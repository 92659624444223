import api from '../axios'

export default {
  cfrs: payload => api.get('sanctum/csrf-cookie'),
  login: payload => api.post('login', payload),
  logout: payload => api.post('/logout', payload),
  getCurrentUser: payload => api.get('/api/profile', payload),
  register: payload => api.post('/register', payload),
  resetPassword: payload => api.post('/api/profile/change-password', payload),
  profileUpdate: payload => api.post('api/profile/update', payload),
  forgetPassword:payload => api.post('/password/email', payload),
  passwordReset: payload => api.post('/password/reset', payload),
  emailConfirm: (user_id, code, expire, signature) => api.get(`email/verify/${user_id}/${code}?expires=${expire}&signature=${signature}`),

  profileUpload:(payload) => api.post('api/profile/image', payload),
  profileRemove:() => api.delete('api/profile/image/remove'),

  getQualificationsList : () => api.get(`api/profile/qualification-list`),
  updateProfessionalInfo : (payload) => api.post('api/profile/professional-info/update',payload),
  updateUnAvailabilities : (payload) => api.post('api/profile/unavailability',payload),
  getMyUnAvailabilities : (payload) => api.get('api/profile/unavailability')
}
