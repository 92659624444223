export default [
    {
        path: '/',
        name: 'login',
        component: () => import('@/views/auth/login.vue'),
        meta: {
            layout: 'full',
            redirectIfLoggedIn: true,
            noAuth: true,
        },
    },
    {
        path: '/register',
        name: 'auth.register',
        component: () => import('@/views/auth/register.vue'),
        meta: {
            layout: 'full',
            redirectIfLoggedIn: true,
            noAuth: true,
        },
    },
    {
        path: '/forgot-password',
        name: 'auth.login',
        component: () => import('@/views/auth/forgot-password.vue'),
        meta: {
            layout: 'full',
            redirectIfLoggedIn: true,
            noAuth: true,
        },
    },
    {
        path: '/password/reset/:token',
        name: 'auth.reset',
        component: () => import('@/views/auth/reset-password.vue'),
        meta: {
            layout: 'full',
            redirectIfLoggedIn: true,
            noAuth: true,
        },
    },
    {
        path: '/invitations/:token',
        name: 'auth.reset',
        component: () => import('@/views/auth/invitations-accept.vue'),
        meta: {
            layout: 'full',
            // redirectIfLoggedIn: true,
            noAuth: true,
        },
    },
    {
        path: '/email/verify/:user_id/:code',
        name: 'auth.reset',
        component: () => import('@/views/auth/email-verify'),
        meta: {
            layout: 'full',
            redirectIfLoggedIn: true,
            noAuth: true,
        },
    }
]
