import Vue from 'vue'
import VueRouter from 'vue-router'
import profileRoute from './modules/profile_route'
import schedulerRoute from './modules/calendar_route'
import auth_route from "@/router/modules/auth_route";
import dashboard_route from "@/router/modules/dashboard_route";
import superAdminRoute from '@/router/modules/super_admin_route';
import locumRoute from '@/router/modules/Locum_route'



Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        ...profileRoute,
        ...auth_route,
        ...dashboard_route,
        ...superAdminRoute,
        ...locumRoute

    ],
})

export default router
