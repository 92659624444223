import Vue from 'vue'
import {ModalPlugin, ToastPlugin} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import authController from '@/router/auth_controller'
import {SchedulePlugin} from '@syncfusion/ej2-vue-schedule'
import router from './router'
import store from './store'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

// Axios Mock Adapter


// Global Components
import './global-components'
// calendar
// eslint-disable-next-line import/order
// 3rd party plugins
// import '@axios'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/vue-mixins'
import '@/libs/vue-lodash'
import '@/libs/vee-validation'
import '@/libs/vue-select'
import '@/libs/sweet-alerts'

// Vue sheduler
//Common mixins
import MessagesMixin from "@/libs/mixins/MessagesMixin";
import CommonMixin from '@/mixins/CommonMixin'

//cookies
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);




Vue.use(SchedulePlugin)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

authController.init(router, store)

Vue.mixin({
    mixins: [MessagesMixin,CommonMixin]
})

// custom validation

import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { numeric, required, email, min, max } from 'vee-validate/dist/rules'

extend('emailTest', {
    validate: value => value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g),
    message: 'Please enter valid email address'
})
// extend('required', {
//     ...required,
//     message: 'This field is required'
// })

extend('tel', {
    validate: value => value.match(/^[0-9,+ ]*$/u),
    message: 'Telephone Number must not contain any letter or special character'
})
extend('min_tel', {
    validate: value => value.length > 6,
    message: 'Contact number must contain at least 7 numbers.'
})
extend('max_tel', {
    validate: value => value.length < 17,
    message: 'Contact number allowed only 16 numbers '
})

extend('max_input', {
    validate: value => value.length < 12,
    message: 'Only 12 digits are allowed  for Smart Card Number'
})


new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
