import config from '@/constants/config'
import moment from 'moment'
import {statusWithColors} from '@/constants/config'


export default {
  methods: {
    getUserImage (update = false) {
      return `${config.apiUrl}api/profile/user/image?${update ? Date.now() : ''}`
    },
    getWeekDayNumberViaDate (day) {
      return moment(moment(moment().startOf('week').add(day.day_number, 'days').format('DD-MM-YYYY') + `-` + day.times[0].start).utc().format('DD-MM-YYYY')).day()
    },
    getUTCConvertedTime(day_number,time) {
      return moment(moment().startOf('week').add(day_number , 'days').format('DD-MM-YYYY') + `-` + time).utc().format('HH:mm:ss')
    },

    makeUpperCase (value) {
      return  _.startCase(_.toUpper(value))
    },
    getStatusWithColor (state) {
      return _.find(statusWithColors, {text: state})
    },
  }
}
