export default [
{
    path: '/locum-profile/:id',
        component: () => import('@/views/locum/locum-profile/profile.vue'),
    name: 'locum,single',
    meta: {
    noAuth: true,
        title: 'Locum',
},
},

{
    path: '/locum',
        name: 'locum',
    component: () => import('@/views/locum/index.vue'),
    meta: {
    noAuth: true,
        title: 'locum',
}
}

]