export default [
    {
        path: '/applier-profile/:id',
        component: () => import('@/views/super-admin/applier-profile/profile.vue'),
        name: 'applier,single',
        meta: {
            noAuth: true,
            title: 'Applier',
        },
    },

    {
        path: '/super-admin',
        name: 'super-admin',
        component: () => import('@/views/super-admin/index.vue'),
        meta: {
            noAuth: true,
            title: 'Super Admin',
        }
    }
]